<template>
    <v-navigation-drawer
        :value="isDrawerOpened"
        fixed
        right
        width="350"
        class="elevation-10"
    >
        <div class="text-center">
        <v-icon size="40" color="primary">mdi-cart</v-icon>
        <h2 class="text-h6 primary--text font-weight-bold">Carrinho</h2>
        </div>

        <div class="mt-7">
        <v-row class="px-7">
            <v-col cols="12" lg="6">
            <v-sheet @click="$emit('addCartProductsToBudget')" style="cursor: pointer" rounded="lg" height="80" class="success darken-1 d-flex flex-column justify-center align-center">
                <v-icon color="white">mdi-plus</v-icon>
                <span class="text-caption text-center white--text">Adicionar ao orçamento</span>
            </v-sheet>
            </v-col>
            <v-col cols="12" lg="6">
            <v-sheet @click="$emit('clearCart')" style="cursor: pointer" rounded="lg" height="80" class="px-4 error d-flex flex-column justify-center align-center">
                <v-icon color="white">mdi-delete</v-icon>
                <span class="text-caption text-center white--text">Limpar carrinho</span>
            </v-sheet>
            </v-col>
        </v-row>
        </div>

        <v-list dense>
        <v-list-item
            v-for="(item, idx) in cartProducts"
            :key="idx"
        >
            <v-list-item-avatar color="grey lighten-2">
                <v-img v-if="item.images.length > 0" :src="$store.state.imagePath + item.images[0].src" />
                <v-icon small color="primary" v-else>fas fa-box</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ item.product_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.product_reference }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.selectedPrint != undefined">
                    {{ item.selectedTable.printed ? item.selectedTable.title : item.selectedPrint.name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                    <span v-for="(row, idx) in item.rows" :key="idx">
                    <v-chip
                        v-if="idx < 3"
                        color="primary"
                        class="mx-1"
                        outlined
                        x-small
                    >
                        <span>{{ row.quantity }}</span>
                    </v-chip>
                    <span v-else-if="idx == 3">+ {{ item.rows.length - 3 }}</span>
                    </span>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
            <v-btn @click="$emit('removeFromCart', item)" icon>
                <v-icon color="error">mdi-close</v-icon>
            </v-btn>
            </v-list-item-action>
        </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    export default {
        props: {
            isDrawerOpened: { type: Boolean, default: () => false },
            cartProducts: { type: Array, default: () => [] }
        }
    }
</script>